import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { archiveMeeting } from '../../../graphql/meetings';
import { trackWebEvent } from '../../../helpers/analytics';
import { kTranscripts } from '../../../helpers/routes';
import { archiveMeeting as archiveMeetingAction } from '../../../redux/modules/global';

export const useArchiveMeeting = (options: {
  meetingId: string;
  fullSize: boolean;
  onCompleted?: () => void;
}): {
  trigger: (isCurrent: boolean) => void;
  loading: boolean;
} => {
  const { meetingId, fullSize, onCompleted } = options;

  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onArchive = useCallback(
    async (isCurrentMeeting: boolean) => {
      trackWebEvent('Clicked Link - Archive Meeting', {
        place: `meeting-actions-${fullSize ? 'text' : 'icons'}`,
      });
      if (isCurrentMeeting) {
        onCompleted?.();
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'You can delete this meeting using Tactiq widget during the meeting, or 5 minutes after it ends',
            id: 'uTiTWt',
            description: 'Cannot delete current meeting message.',
          }),
          { variant: 'INFO' }
        );
        trackWebEvent('Could not delete current meeting');
      } else {
        setLoading(true);
        await archiveMeeting(meetingId);
        dispatch(archiveMeetingAction(meetingId));

        onCompleted?.();
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'Your meeting was archived',
            id: 'M+I4AE',
          }),
          { variant: 'SUCCESS' }
        );

        if (fullSize) {
          navigate(kTranscripts);
        }
      }
      setLoading(false);
    },
    [fullSize, intl, meetingId, dispatch, onCompleted, navigate]
  );

  return {
    trigger: onArchive,
    loading,
  };
};
