import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { MeetingReach } from '../../../graphql/operations';
import { trackTranscriptSharingStarted } from '../../../helpers/analytics';

export const useShareMeetingByEmail = (options: {
  meetingId: string;
  fullSize: boolean;
  teamId?: string;
}): { trigger: () => void } => {
  const { fullSize, meetingId, teamId } = options;
  const navigate = useNavigate();

  const onShareByEmail = useCallback(() => {
    trackTranscriptSharingStarted(
      fullSize ? 'Transcript Navbar - Email' : 'Transcripts List - Email',
      teamId
    );

    navigate(
      `/transcripts/${meetingId}/share/link?reach=${MeetingReach.RESTRICTED}`
    );
  }, [fullSize, meetingId, navigate, teamId]);

  return { trigger: onShareByEmail };
};
