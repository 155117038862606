import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { trackTranscriptSharingStarted } from '../../../helpers/analytics';

export const useShareMeeting = (options: {
  meetingId: string;
  fullSize: boolean;
  teamId?: string;
}): { trigger: () => void } => {
  const { fullSize, meetingId, teamId } = options;
  const navigate = useNavigate();

  const onShare = useCallback(() => {
    trackTranscriptSharingStarted(
      fullSize
        ? 'Transcript Navbar - Generic Share'
        : 'Transcripts List - Generic Share',
      teamId
    );

    navigate(`/transcripts/${meetingId}/share`);
  }, [meetingId, fullSize, navigate, teamId]);

  return { trigger: onShare };
};
